import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
import importButton from '@/components/importButton'
import { postPointRecordsBatchImport } from '@/services/members.js'

export default {
  formData: [{
    title: i18n.t('form.members.batchUpdatePoints'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'name',
      name: 'input',
      value: '',
      label: i18n.t('form.common.file'),
      placeholder: '',
      Object: {
        disabled: true,
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.file') })  }
        ]
      }
    }, {
      span: 24,
      prop: 'file',
      name: 'component',
      label: '',
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: importButton,
        label: i18n.t('message.uploadFile'),
        api: postPointRecordsBatchImport,
        isDirect: true
      }
    }]
  }]
}
